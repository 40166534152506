import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import 'tachyons';


export default (props) => (
  <div className="center pa2 flex">
    <Link to={props.to}><Img fluid={props.image} alt="" className="w5 h-auto"/></Link>
    <div className="pa2 display dark-gray f3 mw6 mh4 mv3 h3">
      {props.title}
      <br/>
      <Link to={props.to} className="dark-gray tracked ttu sans-serif f5">Read More &raquo;</Link>
    </div>
  </div>
)
